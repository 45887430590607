body.body--light {
  color: $lightColor;
  background: $leftBar_bg;
  .custom-bg-info {
    padding: 4px 8px;
    border-radius: 4px;
    color: #666666;
  }
}

.body--light {
  .bit-container,
  .bit-container-2,
  .bit-shadow {
    box-shadow: 0px 2px 4px 0px $bit_shadow;
  }
  .bit-container-2 {
    background: $white !important;
  }
  .bit-pagination .q-btn {
    color: $main_font_color !important;
  }
  .theme-cost-text,
  .bit-form-before,
  .pop-title {
    color: $black !important;
  }
  .bind-site .list .q-item:nth-child(2n - 1) {
    background: rgba(0, 0, 0, 0.04);
  }
  .theme-text {
    color: #262626 !important;
  }
  .theme-cancel-btn {
    background: $bgColorEC !important;
  }
  .theme-border {
    border: 1px solid rgba(0, 0, 0, 0.07);
  }
  .theme-rewards-text {
    color: #464646 !important;
  }
  .theme-buytip {
    background-color: #eff3ff;
  }
  .custom-bg-info {
    background-color: #f5f7fd;
  }
}