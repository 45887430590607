body.body--dark {
  color: $darkColor;
  background: var(--q-dark-page);

  .absolute.bg-white.text-center,
  .login-container .container,
  .list-container .bg-white,
  .tree-container.bg-white,
  .text-orange.bg-blue-1,
  .q-dialog .container,
  .q-toolbar.bg-white,
  .custom-div .title,
  .q-tree.bg-white,
  .recommended,
  .login-container,
  .q-drawer {
    background: $mainBgColor !important;
  }
  .left-nav-container .q-item:hover .text-menu-red,
  .theme-sysnotice.text-primary,
  .bit-pagination .text-primary,
  .theme-rewards-text,
  .text-table-checked,
  .text-table-search,
  .bit-form-before,
  .theme-cost-text,
  .btn_test_color,
  .text-grey-10,
  .theme-title,
  .text-grey,
  .theme-text {
    color: $white !important;
  }
  .bg-grey-2,
  .bg-grey-3,
  .bg-green-1,
  .theme-employee,
  .version-info,
  .user-list {
    background: $dark-grey2 !important;
  }
  .progress.row.items-center,
  .bg-blue-1.q-pa-sm,
  .bg-red-1.q-pa-sm,
  .poppular-agent {
    color: $open-font-color !important;
  }
  .bit-container,
  .bit-container-2,
  .left-nav-container,
  .bit-shadow {
    box-shadow: 0px 2px 4px 0px $tableRow !important;
  }
  .left-nav-container .q-item:nth-child(n + 2):hover,
  .q-router-link--active,
  .q-router-link--active:hover {
    color: $white !important;
    background: $primary !important;
  }
  .bit-dropdown:hover,
  .bit-list .q-item:hover,
  .bit-tabs.q-tabs--dense .q-tab:hover {
    background: #474747 !important;
  }
  .theme-border,
  .bottom_border {
    border: 1px solid rgba(255, 255, 255, 0.6) !important;
  }
  .bit-table .bit-table__header {
    color: $white !important;
    background: rgba($tableRow, 0.5) !important;
    box-shadow: none !important;
  }
  .bit-table .bit-table__body > .row,
  .bit-table .bit-table__body .row1 {
    background: rgba($tableRow, 0.5) !important;
    box-shadow: none !important;
    &:hover {
      cursor: pointer;
      background-color: rgba($tableRow, 0.9) !important;
    }
  }
  .bit-container-2 {
    background: $mainBgColor !important;
    box-shadow: 0 2px 4px 0 $tableRow !important;
  }
  .q-bar--dark,
  .transfer-list {
    color: $white !important;
    background: $mainBgColor !important;
  }
  .buy-ip .footer {
    background: $mainBgColor !important;
    box-shadow: none !important;
  }
  .bit-custom-toggle {
    background: $toggle-color !important;
    color: $white !important;
    .bg-primary {
      background: $primary !important;
      color: $white !important;
    }
  }
  .bind-site .list .q-item:nth-child(2n - 1) {
    background: $dark-grey2;
  }
  .theme-cancel-btn {
    background: $cancelBtn !important;
    color: $white !important;
  }
  .theme-card.card {
    background: $tableRow;
  }
  .payment-item {
    color: $open-font-color !important;
    background: $white !important;
  }
  .q-dialog__backdrop {
    background: rgba(0, 0, 0, 0.6);
  }
  .dialog-container {
    background: $mainBgColor;
    & > div:first-child {
      color: $white !important;
    }
  }
  .common-agreement {
    background: $mainBgColor;
    p {
      color: $white;
    }
    .text {
      h4 {
        color: $white;
      }
    }
  }
  .theme-progress {
    background: $bgColor666 !important;
  }
  .theme-no-shadow {
    box-shadow: none !important;
  }
  .custom-bg-info {
    padding: 4px 8px;
    border-radius: 4px;
    color: $white;
  }
}
